<template>
    <div class="content" style="background: #f6f7fb">
        
        <el-tabs v-model="activeName" @tab-click="handleClick">

            <el-tab-pane label="销售提成" name="first">

              <div class="card" style="display: flex">
                <div class="left" style="flex-shrink: 0; display: flex">
                    <el-button
                        style="height: 40px;"
                        type="primary"
                        @click="addCommiss"
                        icon="el-icon-plus">新增提成规则</el-button>

                        <el-button
                        style="height: 40px;" @click="changeEdit(1)"
                        type="primary">{{isEdit?'编辑':'取消编辑'}}</el-button>
                </div>

                    <div class="line"></div>
                    <div class="middle" style="flex: 1">
                        <Cond
                            @onCond="handleCond"
                            function_code="CommissionSetting"
                            @setCond="handleSetCond"
                        />
                    </div>

                    <div class="right">
                        <TableConf function_code="CommissionSetting" @onRowList="handleRowList"/>
                    </div>

                </div>
                
                <div class='table' v-if="rowList.length">
                    <my-table v-if="activeName=='first'" ref="childs" :activeName="activeName" @getCommissionList="getCommissionList" :tableList="tableList" :rowList="rowList" :isEdit="isEdit" :isAdd='isAdd'></my-table>
                </div>

            </el-tab-pane>

            <el-tab-pane label="旧料提成" name="second">

                <div class="card" style="display: flex" v-if="activeName=='second'">
                    <div class="left" style="flex-shrink: 0; display: flex">
                        <el-button
                            style="height: 40px;"
                            type="primary"
                            @click="addCommiss"
                            icon="el-icon-plus">新增销售提成</el-button>

                            <el-button
                            style="height: 40px;" @click="changeEdit(1)"
                            type="primary">{{isEdit?'编辑':'取消编辑'}}</el-button>
                    </div>

                    <div class="line"></div>
                    <div class="middle" style="flex: 1">
                        <Cond
                            @onCond="handleCond"
                            function_code="OldCommissionSetting"
                            @setCond="handleSetCond"
                        />
                    </div>

                    <div class="right">
                        <TableConf function_code="OldCommissionSetting" @onRowList="handleRowList"/>
                    </div>

                </div>

                  <div class='table' v-if="rowList.length">
                    <my-table :activeName="activeName" @getCommissionList="getCommissionList" :tableList="tableList" :rowList="rowList" :isEdit="isEdit" :isAdd='isAdd'></my-table>
                  </div>

            </el-tab-pane>
        </el-tabs>

         <div class="bottom" v-if="!isEdit||!isAdd">
            <div>
                <el-button type="primary" @click="commissionSetting">保存</el-button>
                <el-button @click="changeEdit(2)">取消</el-button>
            </div>
         </div>

    </div>
</template>

<script>
import {getTotalColumns,getMerchantList,CommissionList,getBandList,getTypeList,
commissionSetting,CommissionDel,} from '@/api/order/offlineOrder/commission'
import TableConf from '@/components/tableConf/Index.vue';
import Cond from "@/components/cond/Index.vue";
import myTable from './components/myTable.vue'
export default{ 
    // 收支汇总 - 表头
    components:{TableConf,Cond,myTable},
    created(){
        this.getCommissionList(1)
        this.getTotalColumnsList('CommissionSetting')
        this.getMerchantList()
    },
    data(){
        return{
             activeName: 'first',
             loading:false,
             rowList:[],
             tableList:[],
             cond: {}, // 搜索的配置
             isEdit:true,  //是否编辑状态
             isAdd:true,   //是否添加状态

             shopList:[],
           
        }
    },
    methods:{

        handleClick(tab, event){
            console.log(tab.paneName,event.name)
            if(tab.paneName=='first'){
                this.getCommissionList(1)
                this.getTotalColumnsList('CommissionSetting')
            }else{
                this.getCommissionList(2)
                this.getTotalColumnsList('OldCommissionSetting')
            }
        },


        //新增 更新列表
        commissionSetting(){
            let type=this.activeName=='first'?1:2;
            commissionSetting({
                type,
                list:this.tableList
            }).then(res=>{
                if(res.code==1){
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    if(!this.isEdit){
                        this.isEdit=true
                    }
                    this.getCommissionList(type)
                }
            })
        },

        //type 1.门店 2.品牌 3.品类
        getSelect(e,idx,field,type){
            console.log(e,field)
            if(type==1){
                let obj = this.shopList.find((item) => {
                    return item.hierarchy_id === e;
                });  
                console.log(obj) 
                this.tableList[idx][field]=obj.hierarchy_name
            }else if(type==2){
                let obj = this.brantList.find((item) => {
                    return item.band_id === e;
                });   
                this.tableList[idx][field]=obj.band_name
            }else if(type==3){
                let obj = this.typeList.find((item) => {
                    return item.goods_type_id === e;
                });   
                this.tableList[idx][field]=obj.title
            }
            console.log(this.tableList[idx])
            
        },

        //改变编辑状态
        changeEdit(type){
            if(type==1){
                this.isEdit=!this.isEdit
                this.isAdd=true
                if(this.activeName=='first'){
                    this.getCommissionList(1)    
                }else {
                    this.getCommissionList(2)
                }
            }else{
                this.isEdit=true
                this.isAdd=true
                if(this.activeName=='first'){
                    this.getCommissionList(1)    
                }else {
                    this.getCommissionList(2)
                }
            }
        },


        //新增提成规则
        addCommiss(){
            this.isAdd=false
            this.tableList.push({})
        },

        //提成列表
        getCommissionList(type=1){
            console.log(type)
            CommissionList({
                type,
            }).then(res=>{
                if(res.code===1){
                    this.tableList=res.data
                    console.log(this.tableList)
                    this.tableList.forEach(item=>{
                        item.isAdd=true
                    })
                }
            })
        },

        handleCond(cond) {
            this.cond = { ...cond };
            this.getList();
        },

         // 处理表格数据
        handleRowList(rowList) {
         this.rowList = rowList;
        },

        //获取配置列表
        getTotalColumnsList(type='CommissionSetting'){
            getTotalColumns({
                function_code:type,// 'CommissionSetting',
                tab: 0
            }).then(res=>{
                this.rowList=res.data
            })
        },

         //搜索配置
        handleSetCond(cond) {
            this.cond = { ...cond };
        },

        handleCond(cond) {
            this.cond = { ...cond };
            this.getMerchantList();
        },

         //获取门店列表
        getMerchantList(){
            getMerchantList().then(res=>{
                if(res.code==1){
                   let shopList= res.data
                   shopList.map(item=>{
                        item.hierarchy_id=Number.parseInt(item.hierarchy_id)
                    })
                    this.shopList=shopList
                }
            })
        },

    },
  

}
</script>

<style scoped lang='less'>
/deep/ .el-form-item{
    margin-bottom: 0;
    margin-right: 20px;
}
.bottom {
  background: white;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #ddd;
}
</style>