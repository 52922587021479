<template>
    <div>
          <el-table :data='tableList' stripe v-loading="loading" height="700" ref="mytables">
                <template v-for='(item, index) in rowList'>
                    
                    <!--   v-if="item.filed_status" -->
                    <el-table-column
                        align='center'
                        :key='index'
                        :prop="item.field_alias"
                        :label='item.field_text'
                        :width="item.t_width"
                        :filters="item.filed_status"
                        >

                        <template slot-scope="scope">

                            <!-- 编辑状态 -->
                            <template v-if="!isEdit">

                                 <!--输入框  -->
                                <template v-if="item.field_type=='string'">

                                    <!-- 折扣区间 -->
                                    <template v-if="item.field_alias=='min_discount'">
                                        <div style="display:flex;">
                                            <el-input-number :precision="2" :step="0.1" controls-position='right' class="numberInput" v-model="scope.row.min_discount" />
                                            
                                            <el-input-number :precision="2" :step="0.1" controls-position='right' class="numberInput" v-model="scope.row.max_discount" />            
                                        </div>
                                    </template>
                                    
                                    <!-- 金重区间 -->
                                    <template v-else-if="item.field_alias=='min_gold_gram'">
                                        <div style="display:flex;">
                                            <el-input-number :precision="2" :step="0.1" controls-position='right' class="numberInput" v-model="scope.row.min_gold_gram" />
                                            
                                            <el-input-number :precision="2" :step="0.1" controls-position='right' class="numberInput" v-model="scope.row.max_gold_gram" />            
                                        </div>
                                    </template>
                                    
                                    <!-- 主石重区间 -->
                                    <template v-else-if="item.field_alias=='min_storm_gram'">
                                        <div style="display:flex;">
                                            <el-input-number :precision="2" :step="0.1" controls-position='right' class="numberInput" v-model="scope.row.min_storm_gram" />
                                            
                                            <el-input-number :precision="2" :step="0.1" controls-position='right' class="numberInput" v-model="scope.row.max_storm_gram" />            
                                        </div>
                                    </template>

                                    <!-- 默认输入框 -->
                                    <template v-else>
                                        <el-input v-model="scope.row[item.field_alias]" />
                                    </template>

                                </template>
                                
                                <!-- 下拉单选 -->
                                <template v-if="item.field_type==='radio'">

                                    <!-- 门店 -->
                                    <template v-if="item.field_alias=='hierarchy_name'">
                                        
                                        <el-select
                                                v-model="scope.row['hierarchy_id']"
                                                clearable 
                                                filterable=""
                                                @change="getSelect($event,scope.$index,item.field_alias,1)">
                                            <template v-for="items in shopList" >
                                                <el-option
                                                    :label="items.hierarchy_name"
                                                    :value="items.hierarchy_id"
                                                    :key="items.hierarchy_id"
                                                ></el-option>
                                            </template>
                                        </el-select>

                                    </template>

                                    <!-- 品牌 -->
                                    <template v-if="item.field_alias=='band_name'">
                                        <el-select
                                                v-model="scope.row['band_id']"
                                                clearable
                                                filterable=""
                                                @change="getSelect($event,scope.$index,item.field_alias,2)">
                                            <template v-for="items2 in brantList" >
                                                <el-option
                                                    :label="items2.band_name"
                                                    :value="items2.band_id"
                                                    :key="items2.band_id"
                                                ></el-option>
                                            </template>
                                        </el-select>

                                    </template>

                                    <!-- 品类 -->
                                    <template v-if="item.field_alias=='goods_type_name'">
                                        <el-select
                                                v-model="scope.row['goods_type_id']"
                                                clearable
                                                filterable=""
                                                @change="getSelect($event,scope.$index,item.field_alias,3)">
                                            <template v-for="items in typeList" >
                                                <el-option
                                                    :label="items.title"
                                                    :value="items.goods_type_id"
                                                    :key="items.goods_type_id"
                                                ></el-option>
                                            </template>
                                        </el-select>

                                    </template>

                                </template>

                            </template>

                               <!-- 新增 -->
                            <template v-else-if="!isAdd&&!scope.row.isAdd">

                                 <!--输入框  -->
                                <template v-if="item.field_type==='string'">

                                    <!-- 折扣区间 -->
                                    <template v-if="item.field_alias=='min_discount'">
                                        <div style="display:flex;">
                                            <el-input-number :precision="2" :step="0.1" controls-position='right' class="numberInput" v-model="scope.row.min_discount" />
                                            
                                            <el-input-number :precision="2" :step="0.1" controls-position='right' class="numberInput" v-model="scope.row.max_discount" />            
                                        </div>
                                    </template>
                                    
                                    <!-- 金重区间 -->
                                    <template v-else-if="item.field_alias=='min_gold_gram'">
                                        <div style="display:flex;">
                                            <el-input-number :precision="2" :step="0.1" controls-position='right' class="numberInput" v-model="scope.row.min_gold_gram" />
                                            
                                            <el-input-number :precision="2" :step="0.1" controls-position='right' class="numberInput" v-model="scope.row.max_gold_gram" />            
                                        </div>
                                    </template>
                                    
                                    <!-- 主石重区间 -->
                                    <template v-else-if="item.field_alias=='min_storm_gram'">
                                        <div style="display:flex;">
                                            <el-input-number :precision="2" :step="0.1" controls-position='right' class="numberInput" v-model="scope.row.min_storm_gram" />
                                            
                                            <el-input-number :precision="2" :step="0.1" controls-position='right' class="numberInput" v-model="scope.row.max_storm_gram" />            
                                        </div>
                                    </template>

                                    <!-- 默认输入框 -->
                                    <template v-else>
                                        <el-input v-model="scope.row[item.field_alias]" />
                                    </template>


                                </template>
                                
                                <!-- 下拉单选 -->
                                <template v-if="item.field_type==='radio'">

                                    <!-- 门店 -->
                                    <template v-if="item.field_alias=='hierarchy_name'">
                                        
                                        <el-select
                                                v-model="scope.row['hierarchy_id']"
                                                clearable 
                                                filterable=""
                                                @change="getSelect($event,scope.$index,item.field_alias,1)">
                                            <template v-for="items in shopList" >
                                                <el-option
                                                    :label="items.hierarchy_name"
                                                    :value="items.hierarchy_id"
                                                    :key="items.hierarchy_id"
                                                ></el-option>
                                            </template>
                                        </el-select>

                                    </template>

                                    <!-- 品牌 -->
                                    <template v-if="item.field_alias=='band_name'">
                                        <el-select
                                                v-model="scope.row['band_id']"
                                                clearable
                                                filterable=""
                                                @change="getSelect($event,scope.$index,item.field_alias,2)">
                                            <template v-for="items2 in brantList" >
                                                <el-option
                                                    :label="items2.band_name"
                                                    :value="items2.band_id"
                                                    :key="items2.band_id"
                                                ></el-option>
                                            </template>
                                        </el-select>

                                    </template>

                                    <!-- 品类 -->
                                    <template v-if="item.field_alias=='goods_type_name'">
                                        <el-select
                                                v-model="scope.row['goods_type_id']"
                                                clearable
                                                filterable=""
                                                @change="getSelect($event,scope.$index,item.field_alias,3)">
                                            <template v-for="items in typeList" >
                                                <el-option
                                                    :label="items.title"
                                                    :value="items.goods_type_id"
                                                    :key="items.goods_type_id"
                                                ></el-option>
                                            </template>
                                        </el-select>

                                    </template>

                                </template>

                            </template>


                            <!-- 非编辑 添加 状态 -->
                            <template v-else>

                                <!-- 折扣区间 -->
                                <template v-if="item.field_alias=='min_discount'">
                                    <span >{{scope.row.min_discount}}-{{scope.row.max_discount}}</span>
                                </template>

                                <!-- 金重区间 -->
                                <template v-else-if="item.field_alias=='min_gold_gram'">
                                    <span >{{scope.row.min_gold_gram}}-{{scope.row.max_gold_gram}}</span>
                                </template>

                                <!-- 主石重间 -->
                                <template v-else-if="item.field_alias=='min_storm_gram'">
                                   <span>{{scope.row.min_storm_gram}}-{{scope.row.max_storm_gram}}</span> 
                                </template>

                                <!-- 正常显示 -->
                                <template v-else>
                                     {{scope.row[item.field_alias] ? scope.row[item.field_alias]: '--'}}
                                </template>

                            </template>

                        </template>
                    </el-table-column>

                    </template>

                     <!-- <el-table-column>

                     </el-table-column> -->

                    <el-table-column align='center' fixed='right'  field_alias='operation' label='操作'>
                        <template slot-scope='scope'>
                            <el-button type='text' @click="handleDele(scope.row.id,scope.$index)" style="color: #F56C6C;">删除</el-button>
                        </template>
                    </el-table-column>

                </el-table>
    </div>
</template>

<script>
import {getTotalColumns,getMerchantList,CommissionList,getBandList,getTypeList,
commissionSetting,CommissionDel,} from '@/api/order/offlineOrder/commission'
export default{
    props:{
        tableList:Array,
        rowList:Array,
        isEdit:Boolean,
        isAdd:Boolean,
        activeName:String,
    },
    mounted(){
        console.log(this.tableList)
        this.getBandList()
        this.getMerchantList()
        this.getTypeList()
        this.$nextTick(()=>{
            this.$refs.mytables.doLayout()
        })
    },
    data(){
        return{
            loading:false,
            shopList:[],  //门店列表
            brantList:[], //品牌列表
            typeList:[],//品类列表
            
        }
    },
    methods:{

         //删除
         handleDele(id,idx){
            console.log(id,idx)
            this.$confirm(`确定删除?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if(!id){
                    this.tableList.splice(idx,1)
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                }else{
                    let type=this.activeName=='first'?1:2;
                    CommissionDel({
                        id,
                        type,
                    }).then(res=>{
                        if(res.code==1){
                            this.$message({
                                type: 'success',
                                message: '操作成功!'
                            });
                            this.tableList.splice(idx,1)
                            // this.$emit('getCommissionList')
                        }
                    })
                }
            }).catch(() => {});
        },

        //type 1.门店 2.品牌 3.品类
        getSelect(e,idx,field,type){
            if(type==1){
                let obj = this.shopList.find((item) => {
                    return item.hierarchy_id === e;
                });  
                console.log(obj) 
                this.tableList[idx][field]=obj.hierarchy_name
            }else if(type==2){
                let obj = this.brantList.find((item) => {
                    return item.band_id === e;
                });   
                this.tableList[idx][field]=obj.band_name
            }else if(type==3){
                let obj = this.typeList.find((item) => {
                    return item.goods_type_id === e;
                });   
                this.tableList[idx][field]=obj.title
            }
        },

         //获取品类列表
        getTypeList(){
            getTypeList().then(res=>{
                if(res.code==1){
                    this.typeList=res.data
                }
            })
        },  

        //品牌列表
        getBandList(){
            getBandList().then(res=>{
                if(res.code==1){
                    this.brantList=res.data
                  
                }
            })
        },
       
        //获取门店列表
        getMerchantList(){
            getMerchantList().then(res=>{
                if(res.code==1){
                   let shopList= res.data
                   shopList.map(item=>{
                        item.hierarchy_id=Number.parseInt(item.hierarchy_id)
                    })
                    this.shopList=shopList
                }
            })
        },

    }
}
</script>

<style lang='less' scoped>
</style>